import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core";
import { gql } from "@apollo/client";
import { Mutation } from "@apollo/client/react/components";
import { Formik } from "formik";
import { email } from "@homewisedocs/validator/lib/form";
import { USER_EMAIL_MAX_LENGTH } from "@homewisedocs/common/lib/user";
import { WithErrorNotification } from "@homewisedocs/components/lib/WithErrorNotification";
import { FormFieldNameMap } from "@homewisedocs/client-utils/lib/form";
import { LoadingButton } from "@homewisedocs/components/lib/LoadingButton";
import { FormTextField } from "@homewisedocs/manager-common/lib/components/FormTextField";
import { H1, HeaderColor } from "@homewisedocs/components/lib/Header";
import { PageWrapper } from "@homewisedocs/manager-common/lib/components/PageWrapper";
import { isDisabled } from "@homewisedocs/manager-common/lib/utils/formik";
import {
  RequestForgotUsernameEmailMutation,
  RequestForgotUsernameEmailMutationVariables,
} from "../../__generated__/gql";
import { CheckInbox } from "../ForgotPassword/ForgotPasswordPage";

const styles = ({ custom: { colors } }: Theme) =>
  createStyles({
    root: {
      maxWidth: "60rem",
      margin: "0 auto",
    },
    subheader: {
      marginTop: "1rem",
      color: colors.fontMedium,
    },
    formContainer: {
      maxWidth: "50rem",
      margin: "3rem auto 0 auto",
    },
    submitButtonWrapper: {
      marginTop: "2rem",
    },
    sendEmailBtn: {
      width: "18rem",
    },
  });

const REQUEST_FORGOT_USERNAME_EMAIL = gql`
  mutation RequestForgotUsernameEmailMutation($email: String!) {
    requestForgotUsernameEmail(email: $email, clientAppType: MANAGER_APP) {
      success
      error {
        message
      }
    }
  }
`;

const initialValues = {
  email: "",
};
type FormValues = typeof initialValues;
const forgotUsernameFormNames: FormFieldNameMap<keyof FormValues> = {
  email: "email",
};

export const ForgotUsernamePage = withStyles(styles)(
  ({ classes }: WithStyles<typeof styles>) => (
    <PageWrapper requireAuth={false}>
      <div className={classes.root}>
        <WithErrorNotification>
          {({ setErrorNotification }) => (
            <Mutation<
              RequestForgotUsernameEmailMutation,
              RequestForgotUsernameEmailMutationVariables
            >
              mutation={REQUEST_FORGOT_USERNAME_EMAIL}
              onCompleted={result => {
                const { error } = result.requestForgotUsernameEmail;

                if (error) {
                  setErrorNotification(error.message);
                }
              }}
            >
              {(requestForgotUsernameEmail, { data, loading, error }) => {
                if (error) {
                  throw error;
                }

                const usernameEmailSent =
                  data && data.requestForgotUsernameEmail.success;

                return usernameEmailSent ? (
                  <CheckInbox
                    subheader={
                      <p>
                        Check your email for usernames associated with your
                        email address. If it doesn't appear within a few
                        minutes, check your spam folder.
                      </p>
                    }
                  />
                ) : (
                  <>
                    <H1 color={HeaderColor.primary}>Forgot Username</H1>
                    <p className={classes.subheader}>
                      Enter your email address and we will send you a link to
                      verify any usernames associated with that address.
                    </p>
                    <Formik<FormValues>
                      initialValues={{ email: "" }}
                      onSubmit={async values => {
                        await requestForgotUsernameEmail({
                          variables: {
                            email: values.email,
                          },
                        });
                      }}
                    >
                      {({ handleSubmit, submitCount, errors }) => (
                        <form
                          onSubmit={handleSubmit}
                          className={classes.formContainer}
                        >
                          <FormTextField
                            id="email"
                            name={forgotUsernameFormNames.email}
                            label="Enter Your Email Address"
                            validate={email}
                            maxLength={USER_EMAIL_MAX_LENGTH}
                            privateData={false}
                            required
                          />
                          <LoadingButton
                            wrapperClassName={classes.submitButtonWrapper}
                            loading={loading}
                            buttonProps={{
                              primary: true,
                              type: "submit",
                              disabled: isDisabled({
                                loading,
                                submitCount,
                                errors,
                              }),
                              className: classes.sendEmailBtn,
                            }}
                          >
                            Send Email
                          </LoadingButton>
                        </form>
                      )}
                    </Formik>
                  </>
                );
              }}
            </Mutation>
          )}
        </WithErrorNotification>
      </div>
    </PageWrapper>
  )
);
