import { LEGACY_APP_PATH_PREFIX } from "@homewisedocs/manager-common/lib/constants/routes";
import { ProductCategoryType } from "../__generated__/gql";

export const RESET_PASSWORD = "/reset-password";

export const CHANGE_PASSWORD = "/change-password";

// Analytics
export const ANALYTICS = "/analytics";
export const ANALYTICS_KEY_METRICS_TAB = `${ANALYTICS}/key-metrics`;
export const ANALYTICS_TRENDS_TAB = `${ANALYTICS}/trends`;
export const ANALYTICS_PRODUCT_BREAKDOWN_TAB = `${ANALYTICS}/product-breakdown`;
export const ANALYTICS_RUSH_PERFORMANCE_TAB = `${ANALYTICS}/rush-performance`;
export const ANALYTICS_PRODUCT_PROCESSING_TAB = `${ANALYTICS}/product-processing`;

export const enterDataUuidRouteParamName = "uuid" as const;

export interface EnterDataPageRouteParams {
  /**
   * Depending on the `EnterDataPageMode`, this will either be the uuid of the
   * order that contains the product to be processed _or_ the uuid of the
   * association whose questionnaire data is being updated.
   * Note: the uuid is only optional in association data mode; in product
   * processing mode, there should always be a uuid.
   */
  [enterDataUuidRouteParamName]?: string;
}

const friendlyProductNames: Record<
  | ProductCategoryType.APPRAISAL
  | ProductCategoryType.QUESTIONNAIRE
  | ProductCategoryType.INSURANCE_QUESTIONNAIRE,
  string
> = {
  [ProductCategoryType.APPRAISAL]: "appraiser-qnr",
  [ProductCategoryType.QUESTIONNAIRE]: "lender-qnr",
  [ProductCategoryType.INSURANCE_QUESTIONNAIRE]: "insurance-qnr",
};

const makeProductProcessingPaths = () => {
  const prefix = "/process-product";

  return {
    root: prefix,
    getRouteByProductCategory: ({
      productCategory,
      productUUID,
    }: {
      productCategory:
        | ProductCategoryType.APPRAISAL
        | ProductCategoryType.QUESTIONNAIRE
        | ProductCategoryType.INSURANCE_QUESTIONNAIRE;
      productUUID: string;
    }) => {
      const friendlyProductName = friendlyProductNames[productCategory];
      return `${prefix}/${friendlyProductName}/${productUUID}`;
    },
  } as const;
};

const makeHoaDataPaths = () => {
  const prefix = "/association-data";

  return {
    root: prefix,
    getRouteByProductCategory: ({
      productCategory,
      hoaUUID,
    }: {
      productCategory:
        | ProductCategoryType.APPRAISAL
        | ProductCategoryType.QUESTIONNAIRE
        | ProductCategoryType.INSURANCE_QUESTIONNAIRE;
      hoaUUID: string | null;
    }) => {
      const friendlyProductName = friendlyProductNames[productCategory];
      const basePath = `${prefix}/${friendlyProductName}`;
      return hoaUUID ? `${basePath}/${hoaUUID}` : basePath;
    },
  } as const;
};

const settingsRoot = "/settings";
const makeMgmtQnrSettingsPaths = () => {
  const mgmtQnrSettingsPrefix = `${settingsRoot}/questionnaires`;
  return {
    root: mgmtQnrSettingsPrefix,
    quickUpdate: `${mgmtQnrSettingsPrefix}/quick-update`,
    expiration: `${mgmtQnrSettingsPrefix}/expiration`,
    optInQuestions: `${mgmtQnrSettingsPrefix}/opt-in`,
    acctImport: `${mgmtQnrSettingsPrefix}/accounting-import`,
    defaultContacts: `${mgmtQnrSettingsPrefix}/default-contacts`,
    copyQnrData: `${mgmtQnrSettingsPrefix}/copy-data`,
  } as const;
};

export const productProcessingPaths = makeProductProcessingPaths();
export const hoaDataPaths = makeHoaDataPaths();
export const mgmtQnrSettingsPaths = makeMgmtQnrSettingsPaths();

export const DEMAND_PAYEES = "/demand-payees";

export const LEGACY_APP_MANAGEMENT_COMPANY_ACCOUNT_ACCESS = `${LEGACY_APP_PATH_PREFIX}/appres/admintopm.xhtml`;
export const LEGACY_APP_HOMEWISE_ADMIN_HOMEPAGE = `${LEGACY_APP_PATH_PREFIX}/appres/adminwelcome.xhtml`;

export const buildLegacyAppOrderLink = ({
  confNumber,
}: {
  confNumber: string;
}) => `${LEGACY_APP_PATH_PREFIX}/orderpage/${confNumber}`;

/**
 * These routes should only be accessible to management company users or admins
 * currently impersonating a management company.
 */
export const MANAGEMENT_COMPANY_ROUTES_LIST = [
  ANALYTICS,
  DEMAND_PAYEES,
  productProcessingPaths.root,
  hoaDataPaths.root,
  settingsRoot,
];
