import { withStyles, WithStyles, createStyles, Theme } from "@material-ui/core";
import { gql } from "@apollo/client";
import { Mutation } from "@apollo/client/react/components";
import { Link } from "react-router-dom";
import { Formik } from "formik";
import { FormFieldNameMap } from "@homewisedocs/client-utils/lib/form";
import { FormTextField } from "@homewisedocs/manager-common/lib/components/FormTextField";
import { password as passwordValidator } from "@homewisedocs/validator/lib/form";
import { H1, HeaderColor } from "@homewisedocs/components/lib/Header";
import { GridContainer, GridItem } from "@homewisedocs/components/lib/Grid";
import { LoadingButton } from "@homewisedocs/components/lib/LoadingButton";
import { WithErrorNotification } from "@homewisedocs/components/lib/WithErrorNotification";
import { PageWrapper } from "@homewisedocs/manager-common/lib/components/PageWrapper";
import { Button } from "@homewisedocs/components/lib/Button";
import { LEGACY_APP_ROOT } from "@homewisedocs/manager-common/lib/constants/routes";
import { isDisabled } from "@homewisedocs/manager-common/lib/utils/formik";
import {
  ChangePasswordMutation,
  ChangePasswordMutationVariables,
} from "../../__generated__/gql";
import { ChangePasswordPageDataTest } from "./DataTest";

const styles = ({ custom: { colors } }: Theme) =>
  createStyles({
    header: {
      marginTop: "5rem",
    },
    fieldsContainer: {
      margin: "5rem auto 6rem auto",
      width: "90rem",
      maxWidth: "100%",
    },
    updatePasswordButton: {
      margin: "0 auto",
      width: "20rem",
    },
    passwordChangeCompleteText: {
      margin: "4rem 0",
      color: colors.fontMedium,
      fontSize: "2rem",
    },
    passwordRequirements: {
      color: colors.fontMedium,
    },
  });

const CHANGE_PASSWORD_MUTATION = gql`
  mutation ChangePasswordMutation(
    $currentPassword: String!
    $newPassword: String!
  ) {
    changePassword(
      currentPassword: $currentPassword
      newPassword: $newPassword
      clientAppType: MANAGER_APP
    ) {
      success
      error {
        message
      }
    }
  }
`;

const initialValues = {
  currentPassword: "",
  newPassword: "",
  confirmNewPassword: "",
};
type FormValues = typeof initialValues;
const changePasswordFieldNames: FormFieldNameMap<keyof FormValues> = {
  currentPassword: "currentPassword",
  newPassword: "newPassword",
  confirmNewPassword: "confirmNewPassword",
};

export const ChangePasswordPage = withStyles(styles)(
  ({ classes }: WithStyles<typeof styles>) => (
    <PageWrapper>
      <div data-test={ChangePasswordPageDataTest.root}>
        <WithErrorNotification>
          {({ setErrorNotification }) => (
            <Mutation<ChangePasswordMutation, ChangePasswordMutationVariables>
              mutation={CHANGE_PASSWORD_MUTATION}
              onCompleted={({ changePassword }) => {
                if (changePassword.success) {
                  setErrorNotification(null);
                } else if (changePassword.error) {
                  setErrorNotification(changePassword.error.message);
                }
              }}
            >
              {(changePassword, { data, loading, error }) => {
                if (error) {
                  throw error;
                }

                const passwordChangeComplete =
                  data && data.changePassword.success;

                if (passwordChangeComplete) {
                  return (
                    <>
                      <div className={classes.passwordChangeCompleteText}>
                        Your password has been updated.
                      </div>
                      <Button secondary component={Link} to={LEGACY_APP_ROOT}>
                        Back to home
                      </Button>
                    </>
                  );
                }

                return (
                  <>
                    <H1 color={HeaderColor.primary} className={classes.header}>
                      Change Password
                    </H1>
                    <Formik<FormValues>
                      initialValues={initialValues}
                      isInitialValid={true}
                      onSubmit={async values => {
                        const { currentPassword, newPassword } = values;

                        await changePassword({
                          variables: {
                            currentPassword,
                            newPassword,
                          },
                        });
                      }}
                    >
                      {({ handleSubmit, values, errors, submitCount }) => (
                        <form onSubmit={handleSubmit}>
                          <div className={classes.fieldsContainer}>
                            <GridContainer>
                              <GridItem xs={12} md={4}>
                                <FormTextField
                                  id="currentPassword"
                                  name={
                                    changePasswordFieldNames.currentPassword
                                  }
                                  label="Current Password"
                                  type="password"
                                  required
                                />
                              </GridItem>
                              <GridItem xs={12} md={4}>
                                <FormTextField
                                  id="newPassword"
                                  name={changePasswordFieldNames.newPassword}
                                  label="New Password"
                                  type="password"
                                  required
                                  validate={passwordValidator}
                                />
                              </GridItem>
                              <GridItem xs={12} md={4}>
                                <FormTextField
                                  id="confirmNewPassword"
                                  name={
                                    changePasswordFieldNames.confirmNewPassword
                                  }
                                  label="Confirm New Password"
                                  type="password"
                                  required
                                  validate={reEnteredPassword =>
                                    reEnteredPassword === values.newPassword
                                      ? undefined
                                      : "Does not match new password"
                                  }
                                />
                              </GridItem>
                              <GridItem xs={12}>
                                <p className={classes.passwordRequirements}>
                                  For your new password, use at least one
                                  letter, one number, and eight characters.
                                </p>
                              </GridItem>
                            </GridContainer>
                          </div>
                          <LoadingButton
                            loading={loading}
                            wrapperClassName={classes.updatePasswordButton}
                            buttonProps={{
                              secondary: true,
                              type: "submit",
                              disabled: isDisabled({
                                loading,
                                submitCount,
                                errors,
                              }),
                            }}
                          >
                            Update Password
                          </LoadingButton>
                        </form>
                      )}
                    </Formik>
                  </>
                );
              }}
            </Mutation>
          )}
        </WithErrorNotification>
      </div>
    </PageWrapper>
  )
);
