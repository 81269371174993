import * as React from "react";
import { Switch, useHistory } from "react-router";
import { Route } from "react-router-dom";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { Environment } from "@homewisedocs/common/lib/env";
import { fetchChunkWithRetries } from "@homewisedocs/client-utils/lib/chunkFetching";
import { getReactAppEnv } from "@homewisedocs/client-utils/lib/env";
import { H2 } from "@homewisedocs/components/lib/Header";
import { Button } from "@homewisedocs/components/lib/Button";
import { HeaderColor } from "@homewisedocs/components/lib/Header";
import { useAuth } from "@homewisedocs/manager-common/lib/components/Auth";
import { PageWrapper } from "@homewisedocs/manager-common/lib/components/PageWrapper";
import { useMgmtCompanyImpersonation } from "@homewisedocs/manager-common/lib/containers/MgmtCompanyImpersonationProvider";
import {
  ANALYTICS,
  DEMAND_PAYEES,
  LEGACY_APP_HOMEWISE_ADMIN_HOMEPAGE,
  productProcessingPaths,
  hoaDataPaths,
  mgmtQnrSettingsPaths,
} from "./constants/routes";

// Lazily load management company pages, since most won't be used in a given
// session.
const reactLazyWithRetries: typeof React.lazy = importCallback =>
  React.lazy(() => fetchChunkWithRetries(importCallback));

const AnalyticsPage = reactLazyWithRetries(
  () =>
    // This page's webpackChunkName doesn't exactly match the name of the page.
    // That's because if the chunk were to be named "Analytics", we would be
    // serving a JavaScript file called "Analytics.[hash].js" which would match
    // a filtering rule used by some popular ad blocking browser extensions,
    // which would block the file from being loaded on the page.
    import(/* webpackChunkName: "MgmtAnalytics" */ "./pages/Analytics")
);
const DemandPayeesPage = reactLazyWithRetries(
  () =>
    import(/* webpackChunkName: "DemandPayeesPage" */ "./pages/DemandPayees")
);
const ProductProcessingPages = reactLazyWithRetries(
  () =>
    import(
      /* webpackChunkName: "ProductProcessingPages" */ "./pages/ProductProcessingPages"
    )
);
const HoaDataPages = reactLazyWithRetries(
  () => import(/* webpackChunkName: "HoaDataPages " */ "./pages/HoaDataPages")
);
const MgmtQnrSettings = reactLazyWithRetries(
  () =>
    import(
      /* webpackChunkName: "MgmtQnrSettings" */ "./pages/Questionnaires/MgmtPages/MgmtQnrSettings"
    )
);

export const MgmtCompanyPages = () => {
  const { authProps } = useAuth();
  const { impersonatedMgmtCompany } = useMgmtCompanyImpersonation();
  const history = useHistory();

  const isHomeWiseAdminWithoutImpersonation =
    authProps.isAuthenticated &&
    authProps.user.isHomeWiseAdmin &&
    impersonatedMgmtCompany == null;

  // Any page rendered here needs to run in the context of a management company.
  // For management company users, this is simply their management company. For
  // HomeWise admins, they need to have selected a management company to
  // impersonate. If they aren't impersonating a management company, none of
  // these pages will work. In that case, let's redirect them to the admin
  // homepage.
  React.useEffect(() => {
    if (
      isHomeWiseAdminWithoutImpersonation &&
      getReactAppEnv() !== Environment.DEV
    ) {
      history.replace(LEGACY_APP_HOMEWISE_ADMIN_HOMEPAGE);
    }
  }, [isHomeWiseAdminWithoutImpersonation, history]);

  // If an admin isn't impersonating a mgmt company, they'll be redirected away
  // shortly (see above). In the meantime we should stop rendering any content
  // specific to a mgmt company route to avoid flashes of content or errors that
  // could be caused by being rendered by a non-mgmt company user.
  if (isHomeWiseAdminWithoutImpersonation) {
    return getReactAppEnv() === Environment.DEV ? (
      // In local dev mode, we can't redirect to the legacy app. Instead, show a
      // button that allows our engineers to conveniently select a test company
      // to impersonate.
      <DevOnlyActivateImpersonation />
    ) : null;
  }

  return (
    <Switch>
      <Route path={ANALYTICS}>
        <AnalyticsPage />
      </Route>
      <Route path={DEMAND_PAYEES}>
        <DemandPayeesPage />
      </Route>
      <Route path={productProcessingPaths.root}>
        <ProductProcessingPages />
      </Route>
      <Route path={hoaDataPaths.root}>
        <HoaDataPages />
      </Route>
      <Route path={mgmtQnrSettingsPaths.root}>
        <MgmtQnrSettings />
      </Route>
    </Switch>
  );
};

const useDevOnlyActivateImpersonationStyles = makeStyles(
  createStyles({
    root: {
      padding: "0 3rem",
    },
    details: {
      margin: "2rem auto 0 auto",
      maxWidth: "60rem",
    },
    setImpersonationCookieButton: {
      marginTop: "2rem",
    },
  })
);

const DevOnlyActivateImpersonation: React.FC = () => {
  const classes = useDevOnlyActivateImpersonationStyles();

  const { __hwdOnlyStartImpersonatingMgmtCompany } =
    useMgmtCompanyImpersonation();

  return (
    <PageWrapper>
      <div className={classes.root}>
        <H2 color={HeaderColor.primary}>No management company specified</H2>
        <p className={classes.details}>
          Note: In a non-dev environment, the user would be redirected to a page
          where they can select a management company. If you want to view a
          management company page, click the button below.
        </p>
        <Button
          color="primary"
          className={classes.setImpersonationCookieButton}
          onClick={() => __hwdOnlyStartImpersonatingMgmtCompany()}
        >
          Set Impersonation Cookie
        </Button>
      </div>
    </PageWrapper>
  );
};
