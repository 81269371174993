import * as React from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { NAVBAR_Z_INDEX } from "@homewisedocs/components/lib/GenericNavbar";
import { useAuth } from "@homewisedocs/manager-common/lib/components/Auth";
import { useMgmtCompanyImpersonation } from "@homewisedocs/manager-common/lib/containers/MgmtCompanyImpersonationProvider";

const useStyles = makeStyles(({ custom: { colors } }) =>
  createStyles({
    root: {
      background: colors.caution,
      color: colors.fontDark,
      fontSize: "1.4rem",
      width: "100%",
      padding: "0 1rem",
      boxSizing: "border-box",
      // Constrain banner text to one line, ellipsize if necessary
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      zIndex: NAVBAR_Z_INDEX + 1,
      position: "fixed",
      top: 0,
    },
    companyDetails: {
      fontWeight: "bold",
    },
  })
);

export const MgmtCompanyImpersonationBanner: React.FC = () => {
  const classes = useStyles();
  const { impersonatedMgmtCompany } = useMgmtCompanyImpersonation();
  const { authProps } = useAuth();

  if (
    authProps.isAuthenticated &&
    authProps.user.isHomeWiseAdmin &&
    impersonatedMgmtCompany
  ) {
    const { id, name } = impersonatedMgmtCompany;
    return (
      <div className={classes.root}>
        Account:{" "}
        <span className={classes.companyDetails}>
          {name} ({id})
        </span>
      </div>
    );
  }

  return null;
};
