import Raven from "raven-js";
import { Environment } from "@homewisedocs/common/lib/env";
import { getReactAppEnv } from "@homewisedocs/client-utils/lib/env";

// ! This file should be ran as soon as possible, because it will log uncaught
// exceptions to Sentry. If we run any code before this and that code causes an
// uncaught exception, the exception won't get logged.
const ravenDsn = "https://08de3648328d4a00953cdc3011346d47@sentry.io/1470797";
const releaseSha = process.env.REACT_APP_RELEASE_SHA;

try {
  Raven.config(ravenDsn, {
    environment: getReactAppEnv(),
    release: releaseSha,
  }).install();
} catch {
  Raven.config(ravenDsn, {
    environment: Environment.DEV,
    release: releaseSha,
  }).install();
}
