import * as React from "react";
import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  ApolloProvider,
  InMemoryCache,
} from "@apollo/client";
import { RetryLink } from "@apollo/client/link/retry";
import { secondsToMilliseconds } from "date-fns";
import { apolloRetryLinkRetryIf } from "@homewisedocs/client-utils/lib/apolloClient";
import { sentryApolloErrorLink } from "@homewisedocs/client-utils/lib/monitoring/sentryApolloErrorLink";
import {
  CLIENT_APP_TYPE_HEADER_NAME,
  MANAGER_APP_CLIENT_APP_TYPE_HEADER_VALUE,
} from "@homewisedocs/common/lib/constants/clientAppType";
import { dataIdFromObject } from "@homewisedocs/manager-common/lib/utils/apollo";
import introspectionData from "../../__generated__/possibleTypes";
// NOTE: if this import path breaks/changes, be sure to update the value of the
// `saveQnrDataMutationOperationName` variable.
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { SaveQnrDataMutation as _SaveQnrDataMutation } from "../../__generated__/gql";
const saveQnrDataMutationOperationName = "SaveQnrDataMutation";

const buildApolloCache = () => {
  return new InMemoryCache({
    dataIdFromObject,
    possibleTypes: introspectionData.possibleTypes,
    typePolicies: {
      // Allow apollo-client to merge data from separate queries together when
      // dealing with these types. This was the default behavior in
      // apollo-client 2. In apollo-client 3 the default is not to merge data
      // for types that don't have IDs. That's safer in general, but in these
      // cases it should be safe to merge them, which will save the user from
      // re-querying data that's in the cache on the analytics page and the
      // questionnaire settings.
      // See http://web.archive.org/web/20210827152220/https://www.apollographql.com/docs/react/caching/cache-field-behavior/#merging-non-normalized-objects
      CompanyEmployeeAnalyticsFilterSettings: {
        merge: true,
      },
      CompanyEmployeePermissions: {
        merge: true,
      },
      MgmtAnalytics: {
        merge: true,
      },
      MgmtCompanyQuestionnaires: {
        merge: true,
      },
      HoaQuestionnaires: {
        merge: true,
      },
      HwdAdmin: {
        merge: true,
      },
    },
  });
};

// A link to automatically retry requests that fail due to network errors.
// The time between retries starts with the intitial delay configured below,
// then doubles with every attempt after that.
const retryLink = new RetryLink({
  delay: {
    initial: secondsToMilliseconds(0.5),
    // Max time to wait between retries
    max: secondsToMilliseconds(4),
    // With jitter enabled, the delay can be anywhere from 0 to 2x the
    // calculated delay time. This is to avoid the "thundering herd" problem
    // where the server is down for a period of time and is crushed by
    // simultaneous requests when it comes up. This doesn't seem like the kind of
    // application where that problem is a real risk, though, and it will make
    // our delays much less predictable. Let's disable it.
    jitter: false,
  },
  attempts: {
    max: 6,
    retryIf: apolloRetryLinkRetryIf({
      unretryableOperationNames: [
        // Don't retry the save-qnr-data mutation, which is also responsible for
        // releasing questionnaires. If the mutation ends up being processed
        // multiple times, it could compound whatever difficulties caused it to
        // fail initially. It's also fairly harmless for this mutation to fail;
        // the user's changes will still be present on the questionnaire data
        // page and they can always retry the operation manually by clicking the
        // "Save" or "Release" button again.
        saveQnrDataMutationOperationName,
      ],
    }),
  },
});

const httpLink = new HttpLink({
  uri: "/graphql",
  // The `fetch` implementation in older browsers may not send cookies along
  // with requests, even at the same origin, unless explicitly directed.
  credentials: "same-origin",
  // Send a header indicating the request is from the manager app.
  headers: {
    [CLIENT_APP_TYPE_HEADER_NAME]: MANAGER_APP_CLIENT_APP_TYPE_HEADER_VALUE,
  },
});

const buildApolloClient = () => {
  return new ApolloClient({
    // Apollo Client passes this value in a header that Apollo Server sends to
    // Apollo Studio so that it can identify which client sent the operation.
    // See docs: https://www.apollographql.com/docs/studio/metrics/client-awareness/#using-apollo-server-and-apollo-client
    // TODO: Consider specifying `version` too.
    name: MANAGER_APP_CLIENT_APP_TYPE_HEADER_VALUE,
    // ! Always make sure that the last link in this array is a terminating link
    link: ApolloLink.from([sentryApolloErrorLink, retryLink, httpLink]),
    cache: buildApolloCache(),
    assumeImmutableResults: true,
  });
};

export const CustomApolloProvider: React.FC = ({ children }) => {
  // Use `useRef` hook to instantiate the client once when the provider mounts
  const client = React.useRef(buildApolloClient());
  return <ApolloProvider client={client.current}>{children}</ApolloProvider>;
};
