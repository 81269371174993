import "whatwg-fetch"; // Fetch polyfill. DON'T MOVE OTHER IMPORTS ABOVE THIS!
import "core-js"; // Inserts polyfills needed to support older browsers (e.g. IE 11). DON'T MOVE OTHER IMPORTS ABOVE THIS!
import "./monitoring/sentry/configureRaven"; // Configures raven. Do this import as early as possible to start logging errors
import * as ReactDOM from "react-dom";
import { ThemeProvider } from "@material-ui/core/styles";
import { BrowserRouter as Router } from "react-router-dom";
import { WarnLegacyBrowser } from "@homewisedocs/components/lib/WarnLegacyBrowser";
import { AuthProvider } from "@homewisedocs/components/lib/Auth";
import { RobotsProvider } from "@homewisedocs/components/lib/Robots";
import { MANAGER_APP_AUTH_TOKEN_PAYLOAD_COOKIE_NAME } from "@homewisedocs/common/lib/constants/auth";
import { CssBaseline } from "@homewisedocs/components/lib/CssBaseline";
import { MgmtCompanyImpersonationProvider } from "@homewisedocs/manager-common/lib/containers/MgmtCompanyImpersonationProvider";
import { CustomApolloProvider } from "./containers/CustomApolloProvider";
import { theme } from "./theme/material";
import {
  ErrorBoundary,
  ErrorBoundaryVariant,
} from "./containers/ErrorBoundary";
import { App } from "./App";

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <CssBaseline />
    {/* ErrorBoundary renders an error page that relies on the theme
    object provided by the ThemeProvider. Ideally the "top level" error
    boundary would be rendered above (and thus catch errors from) absolutely
    all other components, but it's tricky to build an error page that doesn't
    depend on the theme - and any mistake would, ironically, throw an
    uncaught error and break our error page. It seems unlikely that the (very
    simple) ThemeProvider component would throw the kind of error that we
    would miss in development, so this seems like the least-risky approach.
    */}
    <ErrorBoundary variant={ErrorBoundaryVariant.CRITICAL_SUBTREE}>
      <WarnLegacyBrowser>
        <AuthProvider
          payloadCookieName={MANAGER_APP_AUTH_TOKEN_PAYLOAD_COOKIE_NAME}
        >
          <MgmtCompanyImpersonationProvider>
            <CustomApolloProvider>
              <RobotsProvider>
                <Router>
                  <App />
                </Router>
              </RobotsProvider>
            </CustomApolloProvider>
          </MgmtCompanyImpersonationProvider>
        </AuthProvider>
      </WarnLegacyBrowser>
    </ErrorBoundary>
  </ThemeProvider>,
  document.getElementById("root")
);
