import { Theme } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { gql } from "@apollo/client";
import { Mutation } from "@apollo/client/react/components";
import { Formik } from "formik";
import { Link } from "react-router-dom";
import { password as passwordValidator } from "@homewisedocs/validator/lib/form";
import { H1, HeaderColor } from "@homewisedocs/components/lib/Header";
import { GridContainer, GridItem } from "@homewisedocs/components/lib/Grid";
import { FormTextField } from "@homewisedocs/manager-common/lib/components/FormTextField";
import { Button } from "@homewisedocs/components/lib/Button";
import { LoadingButton } from "@homewisedocs/components/lib/LoadingButton";
import { parseQueryStringParameters } from "@homewisedocs/client-utils/lib/routing";
import { WithErrorNotification } from "@homewisedocs/components/lib/WithErrorNotification";
import {
  isTokenValidAtTime,
  decodeBasicToken,
} from "@homewisedocs/components/lib/Auth/utils";
import { FormFieldNameMap } from "@homewisedocs/client-utils/lib/form";
import { useAuthRedirects } from "@homewisedocs/manager-common/lib/containers/useAuthRedirects";
import { PageWrapper } from "@homewisedocs/manager-common/lib/components/PageWrapper";
import { isDisabled } from "@homewisedocs/manager-common/lib/utils/formik";
import {
  FORGOT_PASSWORD,
  MANAGER_LOGIN,
} from "@homewisedocs/manager-common/lib/constants/routes";
import { ForgotPasswordPage } from "../../pages/ForgotPassword";
import {
  ResetPasswordMutation,
  ResetPasswordMutationVariables,
} from "../../__generated__/gql";

const PASSWORD_REQUIREMENTS_TEXT =
  "Use at least one letter, one number, and eight characters.";

const useStyles = makeStyles(({ custom: { colors }, breakpoints }: Theme) => {
  const mobileBreakpoint = breakpoints.down("sm");

  return createStyles({
    root: {
      maxWidth: "80rem",
      margin: "0 auto",
    },
    subheader: {
      color: colors.fontMedium,
    },
    formContainer: {
      marginTop: "4rem",
    },
    passwordRequirements: {
      color: colors.fontMedium,
    },
    submitButtonWrapper: {
      marginTop: "3rem",
    },
    successSubheader: {
      color: colors.fontMedium,
      lineHeight: 1.5,
      marginTop: "5rem",
      [mobileBreakpoint]: {
        marginTop: "3rem",
      },
    },
    loginButton: {
      width: "20rem",
      marginTop: "12rem",
      [mobileBreakpoint]: {
        marginTop: "8rem",
      },
    },
    badTokenHeader: {
      color: colors.danger,
    },
    badTokenCopy: {
      marginTop: "3rem",
      marginBottom: "3rem",
    },
  });
});

const initialValues = { newPassword: "", confirmNewPassword: "" };
type FormValues = typeof initialValues;
const ResetPasswordFormNames: FormFieldNameMap<keyof FormValues> = {
  newPassword: "newPassword",
  confirmNewPassword: "confirmNewPassword",
};

const RESET_PASSWORD_MUTATION = gql`
  mutation ResetPasswordMutation(
    $newPassword: String!
    $passwordResetToken: String!
  ) {
    resetPassword(
      newPassword: $newPassword
      passwordResetToken: $passwordResetToken
      clientAppType: MANAGER_APP
    ) {
      success
      error {
        message
      }
    }
  }
`;

export const ResetPasswordPage = () => {
  const passwordResetToken = getPasswordResetToken();

  if (!passwordResetToken) {
    return <ResetPasswordTokenIllegible />;
  }

  let resetTokenIsValid: boolean;
  try {
    const decodedToken = decodeBasicToken(passwordResetToken);
    resetTokenIsValid = isTokenValidAtTime(decodedToken, new Date());
  } catch {
    return <ResetPasswordTokenIllegible />;
  }

  if (resetTokenIsValid) {
    return <ResetPasswordPageContent />;
  } else {
    // The reset token has expired; show the forgot password page (with some
    // small changes in style/text) so the user can request a new one
    return <ForgotPasswordPage forExpiredResetToken />;
  }
};

const ResetPasswordPageContent = () => {
  const classes = useStyles();
  return (
    <PageWrapper requireAuth={false} searchEngineIndexingDiscouraged>
      <WithErrorNotification>
        {({ setErrorNotification }) => (
          <Mutation<ResetPasswordMutation, ResetPasswordMutationVariables>
            mutation={RESET_PASSWORD_MUTATION}
            onCompleted={({ resetPassword }) => {
              if (resetPassword.error) {
                setErrorNotification(resetPassword.error.message);
              }
            }}
            onError={() =>
              setErrorNotification(
                "Sorry, we were unable to reset your password. Please try again."
              )
            }
          >
            {(resetPassword, { data, loading }) =>
              data?.resetPassword.success ? (
                <ResetPasswordSuccess />
              ) : (
                <div className={classes.root}>
                  <H1 color={HeaderColor.primary}>Reset Your Password</H1>
                  <p className={classes.subheader}>
                    Please create a new password to continue logging in.
                  </p>
                  <Formik<FormValues>
                    initialValues={initialValues}
                    onSubmit={async values => {
                      const passwordResetToken = getPasswordResetToken();

                      if (passwordResetToken) {
                        await resetPassword({
                          variables: {
                            newPassword: values.newPassword,
                            passwordResetToken,
                          },
                        });
                      } else {
                        setErrorNotification(
                          "Invalid link. Please visit the Forgot Password page again to request a new link."
                        );
                      }
                    }}
                  >
                    {({ handleSubmit, values, submitCount, errors }) => (
                      <form
                        noValidate
                        className={classes.formContainer}
                        onSubmit={handleSubmit}
                      >
                        <GridContainer>
                          <GridItem md={6} xs={12}>
                            <FormTextField
                              id="newPassword"
                              name={ResetPasswordFormNames.newPassword}
                              label="New Password"
                              type="password"
                              required
                              validate={passwordValidator}
                            />
                          </GridItem>
                          <GridItem md={6} xs={12}>
                            <FormTextField
                              id="confirmNewPassword"
                              name={ResetPasswordFormNames.confirmNewPassword}
                              label="Re-enter New Password"
                              type="password"
                              required
                              validate={reEnteredPassword =>
                                reEnteredPassword === values.newPassword
                                  ? undefined
                                  : "Does not match new password"
                              }
                            />
                          </GridItem>
                          <GridItem xs={12}>
                            <p className={classes.passwordRequirements}>
                              {PASSWORD_REQUIREMENTS_TEXT}
                            </p>
                          </GridItem>
                        </GridContainer>
                        <LoadingButton
                          wrapperClassName={classes.submitButtonWrapper}
                          loading={loading}
                          buttonProps={{
                            primary: true,
                            type: "submit",
                            disabled: isDisabled({
                              loading,
                              submitCount,
                              errors,
                            }),
                          }}
                        >
                          Change Password
                        </LoadingButton>
                      </form>
                    )}
                  </Formik>
                </div>
              )
            }
          </Mutation>
        )}
      </WithErrorNotification>
    </PageWrapper>
  );
};

const ResetPasswordSuccess = () => {
  const classes = useStyles();
  const { redirectToLoginPage } = useAuthRedirects();
  return (
    <div className={classes.root}>
      <H1 color={HeaderColor.primary}>Password Reset</H1>
      <p className={classes.successSubheader}>
        Your password was successfully reset. Please log in to continue.
      </p>
      <Button
        className={classes.loginButton}
        primary
        onClick={() =>
          redirectToLoginPage({
            loginRoute: MANAGER_LOGIN,
            // Don't include the Reset Password page in the browser history.
            // Otherwise, a user might try to navigate back to this page. In
            // that case, they'd see the "Reset Your Password" view (not the
            // success view). If they tried resetting their password once
            // again, the server would reject the request (since the token
            // can only be used once). That would be confusing for the user.
            includeCallingPageInHistory: false,
          })
        }
      >
        Log In
      </Button>
    </div>
  );
};

const ResetPasswordTokenIllegible = () => {
  const classes = useStyles();
  return (
    <PageWrapper requireAuth={false} searchEngineIndexingDiscouraged>
      <div className={classes.root}>
        <H1 className={classes.badTokenHeader}>Invalid Password Reset Link</H1>
        <p className={classes.badTokenCopy}>
          Your password reset link appears to have been modified. Please click
          the link in your email again. Do not modify the link.
        </p>
        <p className={classes.badTokenCopy}>
          If that still doesn't work, please request another password reset
          email.
        </p>
        <Button primary component={Link} to={FORGOT_PASSWORD}>
          Request another email
        </Button>
      </div>
    </PageWrapper>
  );
};

const getPasswordResetToken = () =>
  parseQueryStringParameters(window.location.search).token;
