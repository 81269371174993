import * as React from "react";
import { useRouteMatch, useHistory, useLocation } from "react-router";
import { Route, Switch, Redirect, RouteProps } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useAppLocationTracking } from "@homewisedocs/components/lib/useAppLocationTracking";
import { RobotsConsumer } from "@homewisedocs/components/lib/Robots";
import { title } from "@homewisedocs/manager-common/lib/constants/meta";
import {
  ROOT,
  MANAGER_LOGIN,
  HWD_ADMIN_PATH_PREFIX,
  LEGACY_APP_PATH_PREFIX,
  LEGACY_APP_ROOT,
  FORGOT_USERNAME,
  FORGOT_PASSWORD,
} from "@homewisedocs/manager-common/lib/constants/routes";
import { useAuth } from "@homewisedocs/manager-common/lib/components/Auth";
import { Navbar } from "@homewisedocs/manager-common/lib/components/Navbar";
import LoadingPage from "@homewisedocs/manager-common/lib/pages/LoadingPage";
import { ResetStateOnLogout } from "@homewisedocs/manager-common/lib/components/ResetStateOnLogout";
import { AppIdentityTracking } from "@homewisedocs/manager-common/lib/components/AppIdentityTracking";
import { SessionExtender } from "@homewisedocs/manager-common/lib/components/SessionExtender";
import { PageNotFoundPage } from "@homewisedocs/manager-common/lib/pages/PageNotFound";
import LoginPage from "@homewisedocs/manager-common/lib/pages/Login";
import { UnhandledPathPage } from "@homewisedocs/manager-common/lib/pages/UnhandledPathPage";
import {
  RESET_PASSWORD,
  CHANGE_PASSWORD,
  MANAGEMENT_COMPANY_ROUTES_LIST,
} from "./constants/routes";
import {
  ErrorBoundary,
  ErrorBoundaryVariant,
} from "./containers/ErrorBoundary";
import "react-day-picker/lib/style.css";
import "./react-day-picker.css";
import ForgotUsernamePage from "./pages/ForgotUsername";
import ForgotPasswordPage from "./pages/ForgotPassword";
import ResetPasswordPage from "./pages/ResetPassword";
import ChangePasswordPage from "./pages/ChangePassword";
import { MgmtCompanyPages } from "./MgmtCompanyPages";
import { MgmtCompanyImpersonationBanner } from "./components/MgmtImpersonationBanner";

/**
 * It doesn't make sense for authenticated users to visit certain routes, such as
 * the login route. If an authenticated user tries to visit one of those routes,
 * we should redirect them. This component helps simplify that routing logic.
 *
 * The `children` will only be rendered if the path matches and if the user
 * _isn't_ logged in.
 */
const RedirectToCoreAppIfLoggedIn: React.FC<
  Pick<RouteProps, "path" | "exact">
> = ({ path, exact, children }) => {
  const history = useHistory();
  const match = useRouteMatch({
    path,
    exact,
  });
  const { authProps } = useAuth();

  if (!match) {
    return null;
  }

  if (authProps.isAuthenticated) {
    history.replace(LEGACY_APP_ROOT);
    return null;
  }

  // Wrap `children` in fragment to circumvent TS issue with React.FC:
  // https://github.com/DefinitelyTyped/DefinitelyTyped/issues/33006
  return <>{children}</>;
};

export const App = () => {
  const { authProps } = useAuth();

  return (
    <>
      <ErrorBoundary variant={ErrorBoundaryVariant.NON_CRITICAL_SUBTREE}>
        <>
          <RobotsConsumer>
            {({ robotsMetaElement }) => (
              <Helmet title={title}>{robotsMetaElement}</Helmet>
            )}
          </RobotsConsumer>
          <AppLocationTracking />
          <AppIdentityTracking authProps={authProps} />
        </>
      </ErrorBoundary>
      <ErrorBoundary variant={ErrorBoundaryVariant.CRITICAL_SUBTREE}>
        <>
          <ResetStateOnLogout isAuthenticated={authProps.isAuthenticated} />
          <SessionExtender />
          <Navbar />
          <React.Suspense fallback={<LoadingPage />}>
            <Switch>
              <Route path={LEGACY_APP_PATH_PREFIX}>
                <UnhandledPathPage />
              </Route>
              <Route path={HWD_ADMIN_PATH_PREFIX}>
                <UnhandledPathPage />
              </Route>
              <Route path={MANAGER_LOGIN}>
                <LoginPage
                  title="Management Company Login | HomeWiseDocs"
                  header="Management Company Login"
                />
              </Route>
              <Redirect exact path={ROOT} to={MANAGER_LOGIN} />
              <RedirectToCoreAppIfLoggedIn path={FORGOT_USERNAME}>
                <ForgotUsernamePage />
              </RedirectToCoreAppIfLoggedIn>
              <RedirectToCoreAppIfLoggedIn path={FORGOT_PASSWORD}>
                <ForgotPasswordPage />
              </RedirectToCoreAppIfLoggedIn>
              <RedirectToCoreAppIfLoggedIn path={RESET_PASSWORD}>
                <ResetPasswordPage />
              </RedirectToCoreAppIfLoggedIn>
              <Route path={CHANGE_PASSWORD}>
                <ChangePasswordPage />
              </Route>
              <Route path={MANAGEMENT_COMPANY_ROUTES_LIST}>
                <MgmtCompanyImpersonationBanner />
                <MgmtCompanyPages />
              </Route>
              <Route>
                <PageNotFoundPage />
              </Route>
            </Switch>
          </React.Suspense>
        </>
      </ErrorBoundary>
    </>
  );
};

const AppLocationTracking: React.FC = () => {
  // Note: in general, we would simply call these hooks in the `App` component.
  // However, the `useLocation` hook will cause the component it's called in to
  // re-render any time the location changes. In the interest of performance,
  // it's better to call the `useLocation` hook here since it's much simpler and
  // quicker to render this component than `App`.
  const { pathname } = useLocation();
  useAppLocationTracking({ pathname });
  return null;
};
