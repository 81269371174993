import * as React from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core";
import { gql } from "@apollo/client";
import { Mutation } from "@apollo/client/react/components";
import { Formik } from "formik";
import { useResponsive } from "@homewisedocs/components/lib/Responsive";
import { Button } from "@homewisedocs/components/lib/Button";
import { WithErrorNotification } from "@homewisedocs/components/lib/WithErrorNotification";
import { LoadingButton } from "@homewisedocs/components/lib/LoadingButton";
import { FormTextField } from "@homewisedocs/manager-common/lib/components/FormTextField";
import { H1, HeaderColor } from "@homewisedocs/components/lib/Header";
import { FormFieldNameMap } from "@homewisedocs/client-utils/lib/form";
import { useAuthRedirects } from "@homewisedocs/manager-common/lib/containers/useAuthRedirects";
import { PageWrapper } from "@homewisedocs/manager-common/lib/components/PageWrapper";
import { isDisabled } from "@homewisedocs/manager-common/lib/utils/formik";
import {
  RequestPasswordResetEmailMutation,
  RequestPasswordResetEmailMutationVariables,
} from "../../__generated__/gql";

const styles = ({ custom: { colors } }: Theme) =>
  createStyles({
    root: {
      maxWidth: "60rem",
      margin: "0 auto",
    },
    expiredResetTokenHeader: {
      color: colors.danger,
    },
    subheader: {
      marginTop: "1rem",
      color: colors.fontMedium,
    },
    formContainer: {
      maxWidth: "50rem",
      margin: "3rem auto 0 auto",
    },
    submitButtonWrapper: {
      marginTop: "2rem",
    },
    noUsernameParagraph: {
      marginTop: "2rem",
    },
  });

const REQUEST_PASSWORD_RESET_EMAIL_MUTATION = gql`
  mutation RequestPasswordResetEmailMutation($username: String!) {
    requestPasswordResetEmail(username: $username, clientAppType: MANAGER_APP) {
      success
      error {
        message
      }
    }
  }
`;

const initialValues = {
  username: "",
};
type FormValues = typeof initialValues;
const forgotPasswordFormNames: FormFieldNameMap<keyof FormValues> = {
  username: "username",
};

interface ForgotPasswordPageProps {
  forExpiredResetToken?: boolean;
}

export const ForgotPasswordPage = withStyles(styles)(
  ({
    forExpiredResetToken = false,
    classes,
  }: ForgotPasswordPageProps & WithStyles<typeof styles>) => {
    const { mobile } = useResponsive();

    return (
      <PageWrapper requireAuth={false}>
        <div className={classes.root}>
          <WithErrorNotification>
            {({ setErrorNotification }) => (
              <Mutation<
                RequestPasswordResetEmailMutation,
                RequestPasswordResetEmailMutationVariables
              >
                mutation={REQUEST_PASSWORD_RESET_EMAIL_MUTATION}
                onCompleted={result => {
                  const { error } = result.requestPasswordResetEmail;

                  if (error) {
                    setErrorNotification(error.message);
                  }
                }}
              >
                {(requestPasswordResetEmail, { data, loading, error }) => {
                  if (error) {
                    throw error;
                  }

                  const passwordResetEmailSent =
                    data && data.requestPasswordResetEmail.success;

                  return passwordResetEmailSent ? (
                    <CheckInbox
                      subheader={
                        <>
                          <p>
                            We're checking our records. If we find an account
                            associated with the username you entered, we'll
                            email you a link to reset your password. If it
                            doesn't appear within a few minutes, you may want to
                            check your spam folder.
                          </p>
                          <p className={classes.noUsernameParagraph}>
                            If the username you entered is not in our system,
                            you will not receive an email.
                          </p>
                        </>
                      }
                    />
                  ) : (
                    <>
                      {forExpiredResetToken ? (
                        <H1 className={classes.expiredResetTokenHeader}>
                          Password Reset Expired
                        </H1>
                      ) : (
                        <H1 color={HeaderColor.primary}>Forgot Password</H1>
                      )}
                      <p className={classes.subheader}>
                        {forExpiredResetToken ? (
                          <>
                            Your password reset link has expired. Please enter
                            your <strong>username</strong> and we will send you
                            a new link to reset your password.
                          </>
                        ) : (
                          <>
                            Enter your <strong>username</strong> and we will
                            send you a link to reset your password.
                          </>
                        )}
                      </p>
                      <Formik<FormValues>
                        initialValues={initialValues}
                        onSubmit={async values => {
                          await requestPasswordResetEmail({
                            variables: {
                              username: values.username,
                            },
                          });
                        }}
                      >
                        {({ handleSubmit, submitCount, errors }) => (
                          <form
                            onSubmit={handleSubmit}
                            className={classes.formContainer}
                          >
                            <FormTextField
                              id="username"
                              name={forgotPasswordFormNames.username}
                              privateData={false}
                              label={
                                <>
                                  Enter Your <strong>Username</strong>
                                </>
                              }
                              // Even though we're expecting a username here,
                              // we shouldn't validate the format. Legacy users
                              // might have usernames that don't satisfy the newer,
                              // more stringent username validation.
                              required
                            />
                            <LoadingButton
                              wrapperClassName={classes.submitButtonWrapper}
                              loading={loading}
                              buttonProps={{
                                primary: true,
                                type: "submit",
                                disabled: isDisabled({
                                  loading,
                                  submitCount,
                                  errors,
                                }),
                              }}
                            >
                              {mobile
                                ? "Send Reset Email"
                                : "Send Password Reset Email"}
                            </LoadingButton>
                          </form>
                        )}
                      </Formik>
                    </>
                  );
                }}
              </Mutation>
            )}
          </WithErrorNotification>
        </div>
      </PageWrapper>
    );
  }
);

const checkInboxStyles = ({ custom: { colors } }: Theme) =>
  createStyles({
    subheader: {
      marginTop: "1rem",
      color: colors.fontMedium,
    },
    successBackToLoginButton: {
      marginTop: "7rem",
    },
  });

export const CheckInbox = withStyles(checkInboxStyles)(
  ({
    subheader,
    classes,
  }: { subheader: React.ReactNode } & WithStyles<typeof checkInboxStyles>) => {
    const { redirectToLoginPage } = useAuthRedirects();
    return (
      <>
        <H1 color={HeaderColor.primary}>Check Your Inbox</H1>
        <div className={classes.subheader}>{subheader}</div>
        <Button
          className={classes.successBackToLoginButton}
          primary
          onClick={() => redirectToLoginPage()}
        >
          Back to Login
        </Button>
      </>
    );
  }
);
