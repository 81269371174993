import * as React from "react";
import Raven from "raven-js";
import {
  GenericErrorBoundary,
  ErrorBoundaryVariant,
} from "@homewisedocs/components/lib/GenericErrorBoundary";
import { GenericErrorPage } from "@homewisedocs/components/lib/GenericErrorPage";
import { useChunkFetchingErrorHandler } from "@homewisedocs/client-utils/lib/chunkFetching";
import { MANAGER_LOGIN } from "@homewisedocs/manager-common/lib/constants/routes";
import { Navbar } from "@homewisedocs/manager-common/lib/components/Navbar";
import LoadingPage from "@homewisedocs/manager-common/lib/pages/LoadingPage";

export const ErrorBoundary: React.FC<{ variant: ErrorBoundaryVariant }> = ({
  variant,
  children,
}) => {
  const {
    pageReloadingAfterChunkFetchingError,
    handlePossibleChunkFetchingError,
  } = useChunkFetchingErrorHandler();

  return (
    <GenericErrorBoundary
      variant={variant}
      onError={({ error, info }) => {
        Raven.captureException(error, {
          extra: {
            ...info,
            reactErrorBoundary: true,
            errorBoundaryVariant: variant,
          },
        });

        handlePossibleChunkFetchingError(error);
      }}
      renderCriticalErrorContent={({ error }) => {
        if (pageReloadingAfterChunkFetchingError) {
          return (
            <>
              <Navbar />
              <LoadingPage />
            </>
          );
        }
        return <GenericErrorPage error={error} homeRoute={MANAGER_LOGIN} />;
      }}
    >
      {children}
    </GenericErrorBoundary>
  );
};
